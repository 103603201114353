:root {
	--dark: #21373b;
	--grey: #3b616a;
	--white: #ffffff;
	--purple: #621a9c;
	--purple-background: #621a9c34;
	--purple-dark: #4c157a;
	--purple-dark-background: #4c157a67;
}

html {
	scrollbar-width: none;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--white);
	background-color: var(--dark);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@keyframes spin {
	from {
		transform: rotateX(0deg);
		transform: rotateY(0deg);
	}
	to {
		transform: rotateX(360deg);
		transform: rotateY(360deg);
	}
}

@keyframes flush {
	0% {
		filter: blur(0px);
	}

	50% {
		filter: blur(6px);
	}

	100% {
		filter: blur(80px);
	}
}

@keyframes shaded {
	0% {
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
	}

	25% {
		box-shadow: rgba(0, 0, 0, 0.1) 0.5px 1px 3px;
	}

	33% {
		box-shadow: rgba(0, 0, 0, 0.17) 0.3px 4px 9px;
	}

	50% {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}

	75% {
		box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
	}

	99% {
		box-shadow: rgba(0, 0, 0, 0.07) 1px 0px 1px;
	}
}

@keyframes enlight {
	0% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 65%
		);
	}

	25% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 68%
		);
	}

	50% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 72%
		);
	}

	75% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 76%
		);
	}

	99% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 79%
		);
	}
}

@keyframes shutDown {
	0% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 79%
		);
	}

	25% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 76%
		);
	}

	50% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 72%
		);
	}

	75% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 68%
		);
	}

	99% {
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 65%
		);
	}
}

@keyframes flow {
	from {
		right: 100vw;
		opacity: 0;
	}

	to {
		right: 0;
		opacity: 1;
	}
}

@keyframes flow-bigger {
	from {
		right: 100vw;
		opacity: 0;
	}

	to {
		right: 15vh;
		opacity: 1;
	}
}

@keyframes fly {
	0% {
		left: 20vw;
		top: 12vh;
		opacity: 0;
	}

	22% {
		/* transform: rotate(-92deg) scale(0.21); */
		opacity: 0;
	}

	25% {
		left: 12vw;
		top: 10vh;
		transform: rotate(-92deg) scale(0.22);
		opacity: 1;
	}

	45% {
		left: 12vw;
		top: 10vh;
		transform: rotate(-95deg) scale(0.195);
	}

	50% {
		left: 8w;
		top: 10vh;
		transform: rotate(-90deg) scale(0.25);
	}

	52% {
		filter: blur(3px);
		left: 5vw;
		top: 9vh;
		transform: rotate(-88deg) scale(0.25);
	}

	75% {
		filter: blur(2px);
		left: 0;
		margin-left: -150px;
		top: 6vh;
		transform: rotate(-89deg) scale(0.22);
		opacity: 1;
	}

	85% {
		filter: blur(12px);
		left: 0;
		margin-left: -300px;
		top: 3vh;
		transform: rotate(-87deg) scale(0.19);
		opacity: 1;
	}

	99% {
		filter: blur(120px);
		margin-left: -550px;
		left: 0;
		top: 0;
		transform: rotate(-82deg) scale(0.001);
		opacity: 0;
	}
}

.spin {
	animation-name: spin;
	animation-duration: 25000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.App {
	background-color: var(--dark);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100vw;
	overflow: hidden;
	gap: 20px;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.box-shadow {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.max-width {
	max-width: 2400px;
}
